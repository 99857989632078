import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import App from 'App'
import { COLOR_CONSTANTS } from 'theme'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import bgImage from 'static/images/bg_trust.jpg'
import searchIcon from 'static/images/trust_search_icon.png'
import agencyIcon from 'static/images/trust_agency_icon.png'
import industryIcon from 'static/images/trust_industry_icon.png'
import worldIcon from 'static/images/trust_world_icon.png'
import trustAgencyVista1 from 'static/images/trust_agencyvista1.png'
import trustAgencyVista2 from 'static/images/trust_agencyvista2.png'
import CarouselReview from 'components/CarouselReview'
import { AGENCY_VISTA_REVIEWS } from 'consts'
import badgeLogo from '../static/images/badge.png'
import ImageText from './About/ImageText'

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const ButtonLink = styled(Button.Primary)`
  display: flex;
  text-decoration: none;
`

const STATS_CONTENT = [
  {
    title: 250000,
    body: 'Search impressions generated as businesses search for agencies.',
    image: searchIcon,
  },
  {
    title: 45000,
    body: 'Marketing agencies on Agency Vista and growing.',
    image: agencyIcon,
  },
  {
    title: 200,
    body: 'Reviews written by verified agency clients.',
    image: industryIcon,
  },
  {
    title: 230,
    body: 'Countries represented by our marketing agencies.',
    image: worldIcon,
  },
]

const MOCK_CONTENT = [
  {
    title: 'Why You Should Trust Agency Vista’s Marketing Agencies',
    body:
      'Agency Vista has developed a data-driven platform that utilizes key account information to assess the best match for your business based on the information you have provided in your search. It is derived from a variety of data points that are designed to help business owners find a marketing agency that is the best fit for them, with the goal of giving business owners peace of mind knowing they are partnering with someone they can trust. ',
    image: badgeLogo,
    button: 'Find Agency',
    buttonLink: '/find-agency',
  },
  {
    title: 'Client Verification',
    body:
      'Agency Vista asks marketing agencies to add clients to their portfolio so that our algorithms can connect them with most businesses whose requirements are the best fit for them. To ensure that agencies are adding clients that they have or are currently working with they must be verified. This verifications is handled by Agency Vista team.',
    image: trustAgencyVista2,
    button: 'Verify Your Clients',
    buttonLink: '/login',
    template: (
      <Text key="/signup" mt="xs" fontSize="l">
        Don’t have a profile yet?{' '}
        <Text fontSize="l" as="span" color="primary">
          <a href="/signup" color="primary">
            Register for<strong> free </strong>today!
          </a>
        </Text>
      </Text>
    ),
  },
  {
    title: 'Verified Badges',
    body:
      'Agency Vista Verified Badge is an indication that a marketing agencies has completed all required steps of our verifiction process. Agency Vista verified badges cannot be purchased, and rely solely on factors that impact the quality of service and how recommendable an agency is.',
    image: trustAgencyVista1,
    button: 'Learn about Verified Badges',
    buttonLink: '/badge',
  },
]

const WhyTrustOurAgencies = ({ pageContext: { totalIndustries, totalViews, totalReviews } }) => {
  const { totalAgencies: { total } = {} } = useStaticQuery(
    graphql`
      query {
        totalAgencies {
          total
        }
      }
    `
  )

  if (totalViews) {
    STATS_CONTENT[0].title = totalViews
  }

  if (total) {
    STATS_CONTENT[1].title = total
  }

  if (totalIndustries) {
    STATS_CONTENT[2].title = totalReviews
  }

  return (
    <App>
      <SEO
        title="Why Trust Our Agencies"
        description="How We Identify Who You Can Trust. Have the confidence in knowing that you’re working with a trusted and reliable company, eliminating wasted time and resources."
        path="/trust/"
        image="/img/discover_perfect_social_agency.jpg"
      />
      <Hero>
        <BgImg src={bgImage} />
        <H1 color="white">How We Identify Who You Can Trust</H1>
        <Box mt="m" maxWidth="900px">
          <StyledH3 color="white">
            Have the confidence in knowing that you’re working with a trusted and reliable company, eliminating wasted
            time and resources.
          </StyledH3>
        </Box>
        <Box mt="m">
          <ButtonLink as="a" href="/search">
            <Text fontWeight="medium">Search agencies</Text>
          </ButtonLink>
        </Box>
      </Hero>

      <Box>
        <Grid
          mb="xxl"
          py="xl"
          px="s"
          width={['auto', 'auto', 'auto']}
          gridTemplateColumns={{ mobile: '1fr', mobileLarge: '1fr 1fr', tablet: 'repeat(4, 1fr)' }}
          gridGap={['l', 'm']}
          backgroundColor="#F0555E"
        >
          {STATS_CONTENT.map((content, index) => {
            return (
              <Flex key={index} flexDirection="column" alignItems="center">
                <LocalImage width="60px" src={content.image} alt={content.body} />
                <CountUp end={content.title} redraw separator="," duration={1.3}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <Text
                        my="s"
                        as="span"
                        fontSize="xxl"
                        color={COLOR_CONSTANTS.WHITE}
                        fontWeight="bold"
                        ref={countUpRef}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <Text fontSize="l" color={COLOR_CONSTANTS.WHITE} textAlign="center">
                  {content.body}
                </Text>
              </Flex>
            )
          })}
        </Grid>
      </Box>

      <Container maxWidth="1080px">
        <Box mt="xxl">
          {MOCK_CONTENT.map((content, index) => (
            <Box key={content.title} mb="xxl">
              <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index !== 1} {...content} />
            </Box>
          ))}
        </Box>
      </Container>
      <Box my="xl">
        <Box mt="xxl">
          <CarouselReview reviews={AGENCY_VISTA_REVIEWS} />
        </Box>
      </Box>
      <Footer />
    </App>
  )
}

WhyTrustOurAgencies.propTypes = {
  pageContext: PropTypes.shape({
    totalIndustries: PropTypes.number,
    totalViews: PropTypes.number,
    totalReviews: PropTypes.number,
  }).isRequired,
}

export default WhyTrustOurAgencies
